<div style="width: 100%; margin: 1rem 0; user-select: none">
    <div data-input-otp-container="true" style="display: flex; align-items: center; gap: 8px; position: relative; cursor: text; user-select: none; pointer-events: none; --root-height: 40px">
        <div class="otp-inputs" style="display: flex; align-items: center">
            @for (item of [1, 2, 3, 4, 5, 6]; track $index) {
                <div #inputs class="otp-input"></div>
            }
        </div>
        <div style="position: absolute; inset: 0; pointer-events: none; user-select: none">
            <input
                autocomplete="one-time-code"
                data-input-otp="true"
                inputmode="numeric"
                pattern="^\d{6}$"
                name="code"
                id="code"
                maxlength="6"
                type="text"
                value=""
                style="
                    position: absolute;
                    inset: 0;
                    width: calc(100% + 40px);
                    height: 100%;
                    display: flex;
                    text-align: left;
                    opacity: 1;
                    color: transparent;
                    pointer-events: all;
                    background: transparent;
                    caret-color: transparent;
                    border: 0 solid transparent;
                    outline: 0 solid transparent;
                    box-shadow: none;
                    line-height: 1;
                    letter-spacing: -0.5em;
                    font-size: var(--root-height);
                    font-family: monospace;
                    font-variant-numeric: tabular-nums;
                    clip-path: inset(0 40px 0 0);
                    -webkit-user-select: none; /* Safari */
                    -ms-user-select: none; /* IE 10 and IE 11 */
                    user-select: none;
                "
                (input)="onInputChange($event)"
                (focus)="onFocus()"
                (blur)="onBlur()"
                (paste)="onPaste($event)"
                #input
                data-np-intersection-state="visible"
                data-input-otp-mss="0"
                data-input-otp-mse="0"
            />
        </div>
    </div>
</div>
