import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MaintenanceService {
    private isUnderMaintenanceSubject = new BehaviorSubject<boolean>(false);
    private backAtSubject = new BehaviorSubject<number | null>(null);

    // Observable to subscribe to the maintenance state
    isUnderMaintenance$ = this.isUnderMaintenanceSubject.asObservable();
    backAt$ = this.backAtSubject.asObservable();

    getIsUnderMaintenance(): boolean {
        return this.isUnderMaintenanceSubject.getValue();
    }

    getBackAt(): number | null {
        return this.backAtSubject.getValue();
    }

    setIsUnderMaintenance(val: boolean): void {
        this.isUnderMaintenanceSubject.next(val);
    }

    setBackAt(timestamp: number): void {
        this.backAtSubject.next(timestamp);

        // Automatically update maintenance state based on timestamp
        const now = Math.floor(Date.now() / 1000);
        this.setIsUnderMaintenance(timestamp > now);
    }
}
